import AxiosRequest from "../../utils/axios-request"

export default (uid) ->
  self = this
  AxiosRequest("/admin/#{uid}/order_deliveries/stores", {id: self.orde_delivery.idorder_purchase}, 'post').then ({data}) ->
    option_stores = data.stores.map((e) => ("<option value=\"#{e.idstore}\">#{e.name}</option>"))
    option_couriers = data.couriers.map((e) => ("<option value=\"#{e.id}\">#{e.name}</option>"))

    Swal.fire({
      showCancelButton: true
      confirmButtonText: 'Continuar'
      cancelButtonText: 'Cancelar'
      allowOutsideClick: false
      html:
        '<div class="uk-text-center">'+
          '<h3> Complete los campos</h3>'+
        '</div>'+
        '<div class="uk-text-left">'+
          '<label for="store" class="uk-label uk-form-label">tienda de envío. </label>'+
          "<select id='store' class='uk-input'><option value='0'>Seleccione una opción</option>"+option_stores+"</select>"+
        '</div>'+
        '<div class="uk-text-left">'+
          '<label for="courier" class="uk-label uk-form-label">logistica. </label>'+
          "<select id='courier' class='uk-input'><option value='0'>Seleccione una opción</option>"+option_couriers+"</select>"+
        '</div>'+
        '<div class="uk-text-left">'+
          '<label for="tracking_number" class="uk-label uk-form-label">Guia. </label>'+
          "<input type='text' class='uk-input' id='tracking_number' name='tracking_number'>"+
        '</div>'

      preConfirm: ->
        store = document.getElementById('store').value
        courier = document.getElementById('courier').value
        tracking_number = document.getElementById('tracking_number').value

        if parseInt(store) == 0 || parseInt(courier) == 0 || tracking_number == ''
          Swal.showValidationMessage('Completa los datos.')
        else if parseInt(store) == 0
          Swal.showValidationMessage('Seleccione una tienda')
        else if parseInt(courier) == 0
          Swal.showValidationMessage('Seleccione una empresa de logisticaa.')
        else if tracking_number == ''
          Swal.showValidationMessage('La guia no puede estar vacia.')

    }).then (request) ->
      if request.value
        store = document.getElementById('store').value
        courier = document.getElementById('courier').value
        tracking_number = document.getElementById('tracking_number').value
        store = document.getElementById('store').value
        AxiosRequest("/admin/#{uid}/order_deliveries/store", { id: self.orde_delivery.idorder_purchase, store: store, courier: courier, tracking_number: tracking_number }, 'post').then ({data}) ->
          console.log 'data', data
          Swal.fire({
            toast: true,
            position: 'top-end'
            icon: 'success'
            title: data.message
            showConfirmButton: false
            timer: 4000
          }).then ->
            location.reload()
