import AxiosRequest from "../../utils/axios-request"

export default (uid, approved, process, paid, cancel, dispatch_Collected) ->
  self = this

  # get delivery_states
  $.get('/delivery_states').done (data) =>
    state_find = data.state.find((e) => (e.id == self.orde_delivery.condition_id))
    # filter magic states
    state_filter = data.state.filter((e) =>
      if self.orde_delivery.condition_id == approved
        (e.id != self.orde_delivery.condition_id && e.id != process && e.id != dispatch_Collected)
      else if self.orde_delivery.condition_id == paid && cancel != undefined
        (e.id != self.orde_delivery.condition_id && e.id != process && e.id != approved && e.id != cancel)
      else
        (e.id != self.orde_delivery.condition_id && e.id != dispatch_Collected)
    )

    # options delivery_states
    option_order = state_filter.map((e) => ("<option value=\"#{e.id}\">#{e.name}</option>"))

    Swal.fire({
      showCancelButton: true
      confirmButtonText: 'Cambiar estado'
      cancelButtonText: 'Cancelar'
      allowOutsideClick: false
      html:
        '<h3>Seleccione el nuevo estado. </h3>'+
        "<select id='state_order' class='swal2-input'><option value='#{state_find.id}'>#{state_find.name}</option>"+option_order+"</select>"
      preConfirm: ->
        state_order = document.getElementById('state_order').value
        if self.orde_delivery.condition_id == parseInt(state_order)
          Swal.showValidationMessage('Seleccione un estado diferente')
    }).then (res) =>
      if res.value
        state = document.getElementById('state_order').value
        AxiosRequest("/admin/#{uid}/order_deliveries/change_status", { id: self.orde_delivery.idorder_purchase, state: state }, 'post').then ({data}) ->
          Swal.fire({
            icon: 'success'
            title: data.message
            showConfirmButton: false
            timer: 2000
            timerProgressBar: true
          }).then ->
              location.reload()
